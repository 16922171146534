import SVTU10 from './assets/sert-svtu.png'
import SVTU10N from './assets/sert-svtu-10(n).png'
import SVTU10M from './assets/sert-svtu-10M.png'
import down10 from './assets/Опросный лист_СВТУ-10.pdf'
import down10N from './assets/Опросный лист_СВТУ-10(Н).pdf'
import down10M from './assets/ОПРОСНЫЙ ЛИСТ СВТУ-10М_2021.pdf'


export const product = [
    { title: 'СВТУ-10',
    description: ['Расходомеры-счетчики ультразвуковые СВТУ-10 (далее расходомеры) предназначены для измерений скорости потока, объемного расхода и объема жидкости (в т.ч. для сточных вод с содержанием твердых примесей), протекающей по полностью заполненным трубопроводам.',
                  'Область применения — для технологического и коммерческого учета объема жидкостей, в энергетике, нефтехимической и других отраслях промышленности.',
                  'Описание: ',
                  'Принцип действия расходомеров основан на ультразвуковом методе измерений. Расходомеры измеряют скорости потока, объемный расход и объем жидкости, используя принцип разности времени прохождения ультразвукового сигнала по направлению и против направления потока жидкости.',
                  'Конструктивно расходомеры состоят из:',
                   '- электронного блока (вычислитель — далее ЭБ);',
                   '- первичного преобразователя расхода (далее — ППР) с комплектом ультра- звуковых датчиков расхода (далее — ДР) (если в качестве ППР используется действующий трубопровод, то поставляется только комплект ультразвуковых ДР);',
                   '- кабеля.'],
      src: SVTU10.toString(),
      download: down10.toString()
    },
    { title: 'СВТУ-10(Н)',
    description: ['Расходомеры-счетчики ультразвуковые СВТУ-10(Н) (далее расходомеры) предназначены для измерений скорости потока, объемного расхода и объема жидкости (в т.ч. для сточных вод с содержанием твердых примесей), протекающей по полностью заполненным трубопроводам.',
                  'Область применения — для технологического учета объема жидкостей, в энергетике, нефтехимической и других отраслях промышленности.',
                  'Описание: ',
                  'Принцип действия расходомеров основан на ультразвуковом методе измерений. Расходомеры измеряют скорости потока, объемный расход и объем жидкости, используя принцип разности времени прохождения ультразвукового сигнала по направлению и против направления потока жидкости.',
                  'Конструктивно расходомеры состоят из:', 
                  '- электронного блока (вычислитель — далее ЭБ);',  
                  '- комплекта ультразвуковых накладных датчиков расхода (далее — ДР);', 
                  '- кабеля.'],
    src: SVTU10N.toString(),
    download: down10N.toString()
  },
  { title: 'СВТУ-10М',
  description: ['Счетчики количества теплоты СВТУ-10M (далее теплосчетчики) предназначены для измерений количества теплоты (тепловой энергии).',
                'Область применения — для технологического и коммерческого учета в ЖКХ, промышленности, в том числе для измерения количества теплоты (тепловой энергии в сфере законодательной метрологии).',
                'Описание: ',
                'Принцип работы теплосчетчика состоит в измерении расхода теплоносителя (жидкости) и температуры теплоносителя в трубопроводах с последующим вычислением количества теплоты и других параметров.',
                'Конструктивно расходомеры состоят из:',
                 '- вычислителя;',
                 '- расходомерного участка с ультразвуковыми преобразователями расхода (далее - РУ);',
                 '- комплекта термопреобразователей сопротивления (далее - ТС);',
                 '- преобразователей избыточного давления.'],
    src: SVTU10M.toString(),
    download: down10M.toString()
  },
]