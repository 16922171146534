import { aboutText } from "../text"
import sobstv1 from "../assets/sobstv-1.png"
import sobstv2 from "../assets/sobstv-2.png"

export const About = () => {
  return <div className="about-page">
           <div className="about-page_img-group">
             <img className="about-page_img" src={sobstv1.toString()} alt='sert'></img>
             <img className="about-page_img" src={sobstv2.toString()} alt='sert'></img>
           </div>
           <div className="about-page_text-group">
            <h2 className="about-page_title block-title"> О нашей компании</h2>
            {aboutText.map((el)=>{
              return (
                <p className="about-page_text block-text">{el}</p> )
            })
            }
           </div>
         </div>
};
