
import { Button } from "@mui/material";
import { Logo } from "../header/logo";
import { Menu } from "../header/menu";
import { Contact } from "../header/contact";
import LogoKPE from "../../assets/Logo-footer.png"



export const Footer = () => {
    return (
    <div className="footer">
      <div className="wrapper">
        <div className="footer-column">
         <Logo className="logo footer-logo" src={LogoKPE}/>
         <Contact className='footer-contact' data={['УНП  191182855',
                                                    'Свидетельство о регистрации: 193555311 от 03.06.2021',
                                                    'Юридический адрес: г. Минск: ул.Волоха, 1-303',
                                                    'Время работы:  пн-чт с 8.30 до 17.00;   пт с 8.30 до 16.00']} />
        </div>
        <div className="footer-column">
          <h3 className="footer-title">Информация</h3>
         <Menu className='footer-menu' data={[['/','Главная'], ['/catalog','Продукция'], ['/about','О компании'], ['/contact','Контакты']]}/>
        </div>
        <div className="footer-column">
          <h3 className="footer-title">Контакты</h3>
          <Contact className='footer-contact' data={['+375-17-396-43-68', '+375-17-396-69-57', '+375-29-277-28-74 (МТС)', '+375-29-359-50-69 (A1)', 'op@kpe.by']} />
          <Button variant="outlined" className="footer-btn">Заказать звонок</Button>
        </div>
      </div>
    </div>
    )
}