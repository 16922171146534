
import Page from './pages/page';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import {Header} from './components/header/header';
import { Footer } from './components/footer/footer';

const App = () => {
  return (
    <Router>
      <Header />
      <Page />
      <Footer />
    </Router>
  );
};

export default App;
