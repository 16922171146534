import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { product } from '../product';


type MyProps = {
  value: string,
  setValue: (value: string) => void;
}


export const TabColor = (props:MyProps) =>{

    const {value, setValue} = props;
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(event)
      setValue(newValue);
    };
    return (

    <Box sx={{ width: '100%', display:'flex', justifyContent:'flex-start', marginBottom: '25px', marginRight: '25px'}}>
      <Tabs sx={{color: '#1D283B', fontSize: '44px', marginRight: '70px'}}
        value={value}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value="0" label={product[0].title} />
        <Tab value="1" label={product[1].title} />
        <Tab value="2" label={product[2].title}/>
      </Tabs>
    </Box>

    )
    }