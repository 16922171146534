import React from "react";

type propsLogoType ={
  src:string,
  className: string
}

export const Logo: React.FC<propsLogoType> = (props:propsLogoType) => {
return (
    <a href="/" >
      <img src={props.src} className={props.className} alt="logo" />
    </a>
)
}