
/*import { Button } from "@mui/material";*/
import { Logo } from "./logo";
import { Menu } from "./menu";
import { Contact } from "./contact";
import LogoKPE from "../../assets/Logo.png"



export const Header = () => {
    return (
    <div className="header">
        <div className="header-menu">
          <Logo className="header-logo" src={LogoKPE}/>
          <Menu className='header-menu' data={[['/','Главная'], ['/catalog','Продукция'], ['/about','О компании'], ['/contact','Контакты']]} />
          
        </div>
        <div className="header-contact-wrapper">
          <Contact className='header-contact' data={['+375-17-396-43-68', '+375-17-396-69-57', '+375-29-277-28-74 (МТС)', '+375-29-359-50-69 (A1)', 'op@kpe.by']} />
        </div>
        
    </div>
    )
}