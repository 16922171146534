export const aboutText = [
'ООО «КИПпромэнерго» - белорусский производитель приборов учета расхода и тепловой энергии с опытом работы более 13 лет.',
'В период с 2010 по 2023 год было произведено и поставлено несколько тысяч счетчиков жидкости и количества теплоты СВТУ-10М, расходомеров-счетчиков ультразвуковых СВТУ-10 и СВТУ-10(Н), которые успешно эксплуатируются как на небольших объектах ЖКХ, так и на крупнейших предприятиях энергетики.',
'Наша компания одна из первых на рынке начала установку приборов учета сточных вод. Мы никогда не боялись сложностей, поэтому помогаем клиентам найти решение в самых запутанных ситуациях.  На данный момент мы производим приборы учета для самых разных отраслей: энергетическая, нефтехимическая, пищевая, водоснабжение и водоотведение, теплоснабжение и др.',
'Собственное производство, а также сотрудничество с ведущими проектными организациями позволяет искать новые пути решения различных проблем заказчиков, в том числе выполнение работ «под ключ».',
'Нашему оборудованию доверяют и список клиентов постоянно пополняется.  Вот только некоторые из них:',
'Филиалы РУП «Минскэнерго», РУП «Витебскэнерго», РУП «Брестэнерго», РУП «Гродноэнерго», РУП «Гомельэнерго», РУП «Могилевэнерго», ОАО «Амкодор», ТРУП «Витебское отделение белорусской железной дороги», ЗАО «Атлант», Парк высоких технологий, УП «Минскводоканал», ИООО «Кроноспан», ОАО «Белорусский цементный завод», КУП «Солигорскводоканал», КПУП «Могилевский мусороперерабатывающий завод», ООО «КАНН-Проект», ОАО "Молодечненский молочный комбинат", ОАО "Савушкин продукт" и многие другие.',
'Будем рады видеть Вас в числе наших клиентов!'
]

export const contactText = [
    'Если у вас остались вопросы, то связаться с нами можно по телефонам:',
    '+375-17-396-43-68', '+375-17-396-69-57', '+375-29-277-28-74 (МТС)', '+375-29-359-50-69 (A1)', 
    'или написать нам на почту:',
    'op@kpe.by',
    'Наш офис находится по адресу:  г.Минск, ул. Волоха, д.1, оф.303'

    ]