

type ContactType = {
    data:Array<string>,
    className:string,
}
export const Contact = (props:ContactType) =>{
    const {className, data} = props;
    const classValue= `contact-list ${className}`

return (
    <ul className={classValue}>
       { data.map((el, index)=>{
        return (
          <li className="contact-item" key={index+Date.now()}>{el}</li>)
        })}
    </ul>
)
}