import '../App.css'

import { FirstBlock } from '../components/firstBlock'
import { SecondBlock } from '../components/secondBlock'
import { ThirdBlock } from '../components/thirdBlock'

function Main() {

  return (
    <>
    <FirstBlock/>
    <SecondBlock />
    <ThirdBlock />
    </>
  )
}

export default Main;
