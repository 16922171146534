import { contactText } from "../text";


import map from "../assets/map.png"
export const ContactPage= () => {
  return  <div className="about-page">
            < div className="about-page_text-group">
             <h2 className="about-page_title block-title"> Как с нами связаться</h2>
             {contactText.map((el, ind)=>{
               return (
                 <p className="about-page_text block-text" key={Date.now()+ind}>{el}</p> )
             })
             }
            </div>
             <img className="map"src={map.toString()}></img>
          </div>
};
