
import  Main  from '../pages/mainPage';
import  {About}  from '../pages/aboutPage';
import  {Page404}  from '../pages/404Page';
import  {ContactPage}  from '../pages/contactPage';
import  {CatalogPage}  from '../pages/catalogPage';

import { Routes, Route } from 'react-router-dom';

const  Page  = () => {
    return (
      <main>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </main>
    );
  }


export default Page;
