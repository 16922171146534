import { Link } from 'react-router-dom';

type MenuType = {
    data:Array<Array<string>>,
    className:string,
  
}
export const Menu = (props:MenuType) =>{
    const {className, data} = props;

    const classValue= `menu-list ${className}`;
return (
    <ul className={classValue}>
       { data.map((el, index)=>{

        return (
          <li className="menu-item" key={index+Date.now()}><Link className="menu-item" to={el[0]}>{el[1]}</Link></li>)
        })}
    </ul>
)
}