import food from '../assets/food.svg';
import energetika from '../assets/energetika.svg'
import nefte from '../assets/nefte-him.svg'
import water from '../assets/water.svg'
import waterdisp from '../assets/waterdisp.svg'
import heat from '../assets/heat.svg'




const spheres= [
 { title:'энергетическая',
   src: energetika.toString(),
  },
 {  title:'нефтехимическая',
    src: nefte.toString(),
  },
 {  title:'пищевая',
    src: food.toString(),
  },
  { title:'водоснабжение', 
    src: water.toString(),
  },
 {  title:'теплоснабжение',
 src: heat.toString(),
},
  { title:'водоотведение',
  src: waterdisp.toString(),}
]

export const ThirdBlock = () =>{

    return (
          <div className="block-screen block3">
            <div className="wrapper wrapper-column">
            <h2 className='block-title'>Учет для всех сред и отраслей</h2>
            <ul className='icon-list'>
            {spheres.map((el, ind)=>{
              return (
                <li className='icon-item' key={ind+Date.now()}>
                  <img className='icon-img' src={el.src} alt={el.src} ></img>
                  <p className='icon-text'>{el.title}</p>
                </li> )
            })
            }
            </ul>
            </div>
          </div>
    )
    }