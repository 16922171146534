


export const FirstBlock = () =>{
    return (
          <div className="screen">
            <div className="gta">
              <p className="gta-text">Точный учет - наша профессия</p>
            </div>
          </div>
    )
    }