import * as React from 'react';
import { Button } from "@mui/material"
import { TabColor } from './tab';
import { product } from '../product';
/*import  Modal from './modal'*/




export const SecondBlock = () =>{

    const [index, setIndex] = React.useState('0');
   
  

  
    
 
    return (
          <div className="block-screen" id='prod'>
            <div className="wrapper">
              <img className="block-img" src={product[+index].src} alt="sertificate"></img>
            <div className="block-column">
             <TabColor value={index} setValue={setIndex} />
             {product[+index].description.map((el, ind)=>{
              return <p className="block-text" key={ind+Date.now()}>{el}</p>
             })}
              
              <Button ><a href={product[+index].download} download={product[+index].title}>Скачать опросный лист</a></Button>
            </div> 
            </div>
          </div>
    )
    }